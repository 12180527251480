// import '@hotwired/turbo-rails'
import 'arrive'
import * as bootstrap from 'bootstrap'
import 'sharer.js'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// media center
document.arrive("#year_chooser", { existing: true}, function(el){
  el.addEventListener('change', function(){
    let val = el.value
    if (val != '') { window.location.href = '/media-center/' + val }
  })
})

// close main menu after click on mobiles
document.arrive('nav#main-menu a', { existing: true }, function(el){
  el.addEventListener('click', function(){
    if (window.innerWidth <= 576) {
      var nav = document.querySelector('nav')
      var coll = bootstrap.Collapse.getInstance(nav)
      if (coll) { coll.hide() }
    }
  })
})

// main menu mobile collapsing - toggle burger/x icons
document.arrive('#main-menu', { existing: true }, function(el) {
  ['show.bs.collapse', 'hide.bs.collapse'].forEach(function(evt){
    el.addEventListener(evt, function(event){
      document.querySelectorAll('.toggle-icon').forEach(function(el){ el.classList.toggle('d-none') })
    })
  })
})

// make all external links open in new tab in richtext
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://altopartners.com']):not([href^='https://altopartners.com'])"
  el.querySelectorAll(selector).forEach((e) => { e.setAttribute("target", "_blank") })
})


// scroll to anchor on load when needed
function scrollToAnchor() {
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
}

// sharer.js
function initSharer() { Sharer.init() }

// plausible.io + Turbo
function trackVisit() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
}

// set proper scroll-margin on <html> to handle fixed header
function adjustScrollPadding() {
  const html = document.querySelector('html')
  const header = document.querySelector('header')
  if (header) { html.style.scrollPaddingTop = `${header.getBoundingClientRect().height}px` }
}

// event listeners
document.addEventListener('DOMContentLoaded', function(){
  trackVisit()
  initSharer()
  adjustScrollPadding()
  // scrollToAnchor()
})
window.addEventListener('resize', adjustScrollPadding)
// window.addEventListener('popstate', scrollToAnchor)

// handle Google maps on office detail page
import '~/javascript/map'

// CSS Styles
import '~/stylesheets/website.scss'
